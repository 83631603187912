<template>
	<article
		class="course-analytics-row-container d-flex flex-row align-center mb-4"
	>
		<section
			class="course-analytics-row d-flex flex-row justify-start align-center mr-2"
		>
			<figure><img /></figure>
			<p class="mr-6">{{ courseName }}</p>
			<p class="mr-6">Status: {{ courseStatus }}</p>
			<p class="mr-6">Passes: {{ coursePasses }}</p>
			<p class="mr-6">Failures: {{ courseFails }}</p>
			<p class="mr-6">Approvals: {{ courseApprovals }} %</p>
		</section>
		<section class="d-flex flex-row justify-end align-center ml-2">
			<p class="ml-6 clickable" @click="usassignQuiz">Unassign Quiz</p>
		</section>
	</article>
</template>

<script>
import { remove } from "@/util/requests/remove";
export default {
	name: "QuizAnalyticsRowData",
	props: ["course"],
	computed: {
		courseImageSrc() {
			return this.course?.images[0]?.image.public_url;
		},
		courseName() {
			return this.course?.title;
		},
		courseStatus() {
			return this.course?.published_at === null ? "Draft" : "Published";
		},
		coursePasses() {
			return this.course?.passes;
		},
		courseFails() {
			return this.course?.fails;
		},
		courseApprovals() {
			return this.course?.approval_rates;
		},
		courseId() {
			return this.course?.id;
		},
		courseQuizId() {
			return this.course?.courseQuizId;
		}
	},
	methods: {
		async usassignQuiz() {
			await this.$store.dispatch("setIsLoading", true);
			try {
				await remove(`/course_quiz/${this.courseQuizId}`);
				this.$emit("getCourseData", this.$route.params.id);
				await this.$store.dispatch("setIsLoading", false);
			} catch (error) {
				console.error(error);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
p {
	white-space: nowrap;
}
.course-analytics-row-container {
	background-color: rgb(230, 230, 230);
	padding: 10px 20px;
}
.course-analytics-row {
	width: 100%;
	& section {
		width: 100%;
	}
}
</style>
